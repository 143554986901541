import * as React from "react"
import PropTypes from "prop-types"


const Label = (props) => {
    const { children, variation } = props;
    let classes = "";

    switch (variation) {
        case 'white':
            classes += "uppercase text-borange bg-white";
            break;
        case 'gray':
            classes += "uppercase text-white bg-bdark-gray";
            break;
        default:
            classes += "uppercase"
    }

    return (
        <span className={`rounded-9 ${classes} inline-block pt-3 px-5.25 pb-2.25 font-bryant font-bold text-3.75 tracking-0.15`}>
            {children}
        </span>
    )
}

Label.propTypes = {
    children: PropTypes.node.isRequired,
    variation: PropTypes.string.isRequired,
}

export default Label
