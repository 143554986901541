import React, {useEffect, useRef} from 'react';
import { navigate } from "gatsby";
import { gsap } from "gsap";

import { flattenConfs, getCurrentTime, printDatas, getAutoplaySpeed, fadeIn, fadeOut } from "../../helpers/helpers";

import Layout from "../layout/layout"
import Seo from "../layout/seo"
import Header from "../layout/header";
import Column from "./column";
import { MainTitle } from "../shared/title";


const Index = (props) => {

    const conferencesDays = props.pageContext.data;
    const allConfs = flattenConfs(conferencesDays);
    const currentDate = getCurrentTime(props.location.search);
    const mainRef = useRef();

    printDatas(props.location.search, conferencesDays, currentDate);

    const calendarTimer = () => {
        gsap.delayedCall(getAutoplaySpeed(props.location.search, 30), () => {
            fadeOut(mainRef.current, () => navigate(`/detail/${props.location.search}`));
        });
    }
    useEffect(() => {
        fadeIn(mainRef.current, calendarTimer);
    }, []);

    const title = <MainTitle content={"Conférences à Pollutec"}/>

    return (
        <Layout>
            <Seo title="Calendrier"/>

            <div ref={mainRef} style={{ opacity: 0, visibility: "hidden" }}>
                <Header title={title}/>

                <ul className={`flex flex-row space-x-20`}>
                    { conferencesDays.map((day, index) => (
                        <Column key={index} day={day.node} currentDate={currentDate} allConfs={allConfs}/>
                    ))}
                </ul>
            </div>
        </Layout>
    )
}

export default Index
